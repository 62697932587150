<template>
  <div>
    <s-dialog :open="true" to="stds-dialog-editing-permission" size="md">
      <s-dialog-overlay />
      <s-dialog-panel class="w-[50rem]">
        <st-dialog-header @clickClose="closeDialog">
          {{ $t('studio.gnb.group_myinfo.my_group.detailed_group_edit_per_by_menu') }}
        </st-dialog-header>
        <s-dialog-content>
          <s-dialog-content-body>
            <st-table :columns="columns" :data="menus">
              <template #row="{ row }">
                <st-tr>
                  <td
                    class="text-sm text-on-surface-elevation-2 text-left leading-md align-middle first:pl-20 last:pr-20 px-16 py-12"
                  >
                    {{ $t(row.name) }}
                  </td>
                  <safe-html
                    tag="td"
                    class="text-sm text-on-surface-elevation-2 text-left leading-md align-middle first:pl-20 last:pr-20 px-16 py-12"
                    :html="$t(row.permissions)"
                  />
                </st-tr>
              </template>
            </st-table>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer />
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target name="stds-dialog-editing-permission" />
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

import SafeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTr from '@/components/common/table/st-tr.vue';
import type { Column } from '@/types/table.type';

const emit = defineEmits<{
  close: [];
}>();

const closeDialog = () => {
  emit('close');
};

const columns = ref<Column[]>([
  {
    title: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_menu_name',
    width: '16.8rem'
  },
  {
    title: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_descr',
    width: 'auto'
  }
]);

const menus = ref([
  {
    name: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_grp_home',
    permissions: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_descr1'
  },
  {
    name: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_prj_prod',
    permissions: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_descr2'
  },
  {
    name: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_collection',
    permissions: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_descr3'
  },
  {
    name: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_store_comm',
    permissions: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_descr4'
  },
  {
    name: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_mem_mgmt',
    permissions: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_descr5'
  },
  {
    name: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_biz_bank_info',
    permissions: 'studio.gnb.group_myinfo.my_group.detailed_group_edit_per_descr6'
  }
]);
</script>
